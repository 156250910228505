<template>
  <div><van-empty image="error" description="404：页面丢失" /></div>
</template>

<script>
import { Empty } from "vant";
export default {
  components: {
    [Empty.name]: Empty, // 无数据
  },
};
</script>

<style></style>
